













































































import { Component, Vue } from 'vue-property-decorator';
import { dispatchUpdatePontoAcesso } from '@/store/ponto_acesso/actions';
import { consultarPontoAcesso } from '@/store/ponto_acesso/getters';
import { readLocalidades } from '@/store/localidade/getters';
import { readResponsaveis } from '@/store/responsavel/getters';
import { IPontoAcessoCreateUpdate } from '@/interfaces/ponto_acesso';
import { dispatchGetPontosAcesso } from '@/store/ponto_acesso/actions';
import cacheDominiosEstaticos from '@/store/ponto_acesso/cache';

@Component
export default class AlterarPontoAcesso extends Vue {
  public valid = true;
  public idLocalidade: number = 0;
  public idResponsavel: number = 0;
  public status: boolean = false;
  public macAddress: string = '';
  public latitude: number = 0.0;
  public longitude: number = 0.0;

  public async mounted() {
    await cacheDominiosEstaticos(this.$store);
    this.reset();
  }

  public uppercase() {
    this.macAddress = this.macAddress.toUpperCase();
  }

  public reset() {
    this.$validator.reset();
    if (this.pontoAcesso) {
      this.idLocalidade = this.pontoAcesso.localidade.id;
      this.idResponsavel = this.pontoAcesso.responsavel.id;
      this.status = this.pontoAcesso.status;
      this.macAddress = this.pontoAcesso.mac_address;
      this.latitude = this.pontoAcesso.latitude;
      this.longitude = this.pontoAcesso.longitude;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const pontoAcessoAtualizado: IPontoAcessoCreateUpdate = {
        id_localidade: this.idLocalidade,
        id_responsavel: this.idResponsavel,
        mac_address: this.macAddress,
        status: this.status,
        latitude: this.latitude,
        longitude: this.longitude,
      };

      const atualizou = await dispatchUpdatePontoAcesso(this.$store, {
        id: this.pontoAcesso!.id,
        pontoAcesso: pontoAcessoAtualizado,
      });
      if (atualizou) {
        dispatchGetPontosAcesso(this.$store, 0);
        this.$router.push('/main/ponto_acesso/listar');
      }
    }
  }

  get pontoAcesso() {
    return consultarPontoAcesso(this.$store)(+this.$router.currentRoute.params.id);
  }

  get localidades() {
    return readLocalidades(this.$store);
  }

  get responsaveis() {
    return readResponsaveis(this.$store);
  }
}
